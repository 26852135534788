import React, { Fragment, useState } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import Img from 'gatsby-image'
import { Fade } from 'react-reveal'

import ContainedSection from '../components/ContainedSection'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import materialUiTheme from '../material-ui-theme'

import media from '../utils/media'

const StoreLocator = () => {
  const data = useStaticQuery(graphql`
    query {
      pageHeaderBackgroundImage: file(
        relativePath: { eq: "store-locator-header-banner.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      storeThumbnailImage1: file(
        relativePath: { eq: "machicken-fun-ranch-branch.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      storeThumbnailImage2: file(
        relativePath: { eq: "machicken-the-block-branch.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      storeThumbnailImage3: file(
        relativePath: { eq: "machicken-pavilion-branch.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      storeThumbnailImage4: file(
        relativePath: { eq: "machicken-sta-lucia-branch.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      storeThumbnailImage5: file(
        relativePath: { eq: "machicken-trinoma-branch.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const storesData = [
    {
      id: 1,
      address:
        'Lower G/F Fun Ranch Complex. Frontera Verde E. Rodriguez Ave cor C5, Pasig City',
      location: Locations.PASIG,
      name: 'Ma Chicken Fun Ranch',
      mapQuery: 'ma+chicken+fun+ranch',
      telephone: '8 - 584 6231',
      storeHours: ['Monday to Saturday: 10am-12am', 'Sunday: 10am-11pm'],
      thumbnail: 'storeThumbnailImage1',
    },
    {
      id: 2,
      address:
        '4F SM North Edsa The Block, EDSA corner North Ave., Quezon City',
      location: Locations.QUEZON_CITY,
      mapQuery: 'ma+chicken+sm+the+block',
      name: 'Ma Chicken SM The Block',
      telephone: '8 - 559 2405',
      storeHours: ['Monday to Sunday: 10am-10pm'],
      thumbnail: 'storeThumbnailImage2',
    },
    {
      id: 3,
      address: `Specialty Food #4030 B, Level 4, Trinoma Mall, EDSA cor. North Ave., Quezon City`,
      location: Locations.QUEZON_CITY,
      mapQuery: 'ma+chicken+trinoma+edsa',
      name: 'Ma Chicken Trinoma',
      telephone: '(02) 425 5028',
      storeHours: [
        'Sunday to Thursday: 10am-10pm',
        'Friday to Saturday: 10am-11pm',
      ],
      thumbnail: 'storeThumbnailImage5',
    },
    {
      id: 4,
      address: `Space 167 G/F Bldg. B Pavillion Mall, Binan, Laguna`,
      location: Locations.LAGUNA,
      mapQuery: 'ma+chicken+laguna+pavilion',
      name: 'Ma Chicken Pavilion',
      telephone: '(049) 411 3071',
      storeHours: ['Monday to Sunday: 10am-10pm'],
      thumbnail: 'storeThumbnailImage3',
    },
    {
      id: 5,
      address:
        'Sta.Lucia East Grandmall Marcos Hi-way Felix Ave., Cainta, Rizal',
      location: Locations.RIZAL,
      mapQuery: 'chicken%20mami%20house%20east%20grand%20mall',
      name: 'Ma Chicken Sta. Lucia ',
      telephone: '994 6142',
      storeHours: ['Monday to Sunday: 10am-10pm'],
      thumbnail: 'storeThumbnailImage4',
    },
  ]

  const [selectedStoreId, setSelectedStoreId] = useState(storesData[0].id)

  const selectedStore = storesData.find(({ id }) => id === selectedStoreId)

  const [values, setValues] = React.useState({
    location: 0,
  })

  const handleChange = event => {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }))
  }

  return (
    <Layout
      pageHeaderBackgroundImage={
        data.pageHeaderBackgroundImage.childImageSharp.fluid.src
      }
      pageTitle="Store Locator"
    >
      <SEO title="Store Locator" />
      <S.ContainedSection>
        <Fade top>
          <Paper elevation={3}>
            <S.GridContainer container>
              <S.StoresSectionGridItem item xs={12} sm={4}>
                <Grid container direction="column">
                  <Grid container item>
                    <Paper
                      square
                      elevation={1}
                      style={{
                        flex: 1,
                        padding: materialUiTheme.spacing(2),
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel htmlFor="select-location">
                          Select location
                        </InputLabel>
                        <Select
                          value={values.location}
                          onChange={handleChange}
                          inputProps={{
                            name: 'location',
                            id: 'select-location',
                          }}
                        >
                          <MenuItem value={0}>
                            <em>All locations</em>
                          </MenuItem>
                          <MenuItem value={Locations.QUEZON_CITY}>
                            Quezon City
                          </MenuItem>
                          <MenuItem value={Locations.PASIG}>Pasig</MenuItem>
                          <MenuItem value={Locations.RIZAL}>Rizal</MenuItem>
                          <MenuItem value={Locations.LAGUNA}>Laguna</MenuItem>
                        </Select>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <S.StoresListGridItem item>
                    <List>
                      {storesData
                        .filter(({ location }) =>
                          values.location ? values.location === location : true
                        )
                        .map(
                          (
                            {
                              id,
                              address,
                              name,
                              storeHours,
                              telephone,
                              thumbnail,
                            },
                            index
                          ) => (
                            <Fragment key={id}>
                              <ListItem
                                button
                                onClick={() => setSelectedStoreId(id)}
                                selected={id === selectedStoreId}
                              >
                                <Grid container>
                                  <Grid
                                    item
                                    xs={9}
                                    style={{
                                      paddingRight: materialUiTheme.spacing(3),
                                    }}
                                  >
                                    <Typography
                                      gutterBottom
                                      color="primary"
                                      variant="subtitle1"
                                    >
                                      {name}
                                    </Typography>
                                    <Typography
                                      gutterBottom
                                      color="textSecondary"
                                      variant="body2"
                                    >
                                      {address}
                                    </Typography>
                                    <Typography
                                      gutterBottom
                                      color="textSecondary"
                                      variant="body2"
                                    >
                                      {telephone}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Img
                                      fluid={
                                        data[thumbnail].childImageSharp.fluid
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </ListItem>

                              {index < storesData.length - 1 && <Divider />}
                            </Fragment>
                          )
                        )}
                    </List>
                  </S.StoresListGridItem>
                </Grid>
              </S.StoresSectionGridItem>
              <S.MapGridItem item xs={12} sm={8}>
                <iframe
                  allowFullScreen
                  frameBorder="0"
                  width="100%"
                  height="100%"
                  title="Store Locator Map"
                  src={`https://www.google.com/maps/embed/v1/place?q=${selectedStore.mapQuery}&key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`}
                  style={{ border: 0 }}
                ></iframe>
              </S.MapGridItem>
            </S.GridContainer>
          </Paper>
        </Fade>
      </S.ContainedSection>
    </Layout>
  )
}

const S = {
  ContainedSection: styled(ContainedSection)`
    padding-top: 60px;

    ${media.sm`
      padding-top: 60px;
    `}

    ${media.md`
      padding-top: 80px;
    `}
  `,
  GridContainer: styled(Grid)`
    flex-wrap: wrap-reverse;
  `,
  MapGridItem: styled(Grid)`
    min-height: 320px;

    ${media.sm`
      min-height: 600px;
    `}
  `,
  StoresListGridItem: styled(Grid)`
    max-height: 240px;
    overflow: auto;

    ${media.sm`
      max-height: 520px;
    `}
  `,
  StoresSectionGridItem: styled(Grid)`
    max-height: 320px;
    overflow: hidden;

    ${media.sm`
      max-height: 600px;
    `}
  `,
}

const Locations = Object.freeze({
  QUEZON_CITY: 1,
  PASIG: 2,
  LAGUNA: 3,
  RIZAL: 4,
})

export default StoreLocator
